import React, { useState } from "react";
import Button from "@material-ui/core/Button";

import PaymentForm from "@components/PaymentForm";
import Dialog from "@ui-kit/Dialog";
import { createCss } from "./styles";
import {
  StripeProvider,
  useStripeContext,
} from "@components/PaymentForm/StripeProvider";

interface IProps {
  onClose: () => void;
}

const PaymentsList: React.FC<IProps> = ({ onClose }) => {
  const [loading, setLoading] = useState(false);
  const { createPaymenMethod, fetching, mode } = useStripeContext();
  const css = createCss();

  async function submitForm(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.preventDefault();

    try {
      setLoading(true);
      await createPaymenMethod();
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog open onClose={onClose} title="Payment method" loading={loading}>
      <form data-test="payments-list" css={css.container}>
        <div>
          <PaymentForm />
        </div>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          data-test="submit-btn"
          onClick={submitForm}
          disabled={fetching || mode !== "edit"}
        >
          Save
        </Button>
      </form>
    </Dialog>
  );
};

const PaymentListWrapper: React.FC<IProps> = (props) => {
  return (
    <StripeProvider>
      <PaymentsList {...props} />
    </StripeProvider>
  );
};

export default PaymentListWrapper;
